import React from "react"
import PropTypes from "prop-types"
import Nav from "./navbar/nav.js"
import Footer from "./footer"
import "./layout.css"
import ShoppingCart from "./shoppingCart/shoppingCart.js"
import { useStore } from "../Context/StoreContext.js"
import SEO from "./seo.js"
import { GlobalStyle } from "../styles/GlobalStyle.js"
import Loadable from "react-loadable"

function Loading(props) {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    )
  } else {
    return <div>Loading...</div>
  }
}

const LoadablePixel = Loadable({
  loader: () => import("../components/typeEf/ReactPixel"),
  loading: Loading,
})

const Layout = ({ children }) => {
  const { state } = useStore()

  const output = state?.message
    ?.replace(/[0-9]/g, "")
    ?.replace(/-/g, "")
    ?.trim()

  return (
    <>
      <GlobalStyle />
      <SEO title="" />
      <Nav output={output} />
      <ShoppingCart />
      <div>
        <main
          style={{
            marginTop: `var(--mainMargin)`,
          }}
        >
          {children}
        </main>
        {typeof window !== "undefined" && <LoadablePixel />}
        <Footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </Footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
