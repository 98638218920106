import React from "react"
import Navbar from "./Navbar"

const Nav = (props) => {
  return (
    <>
      <Navbar output={props.output} setshowPopup={props.setshowPopup} />
    </>
  )
}

export default Nav
