import React from "react"
import styled from "styled-components"
import Subscribe from "../templates/Subscribe.js"
import {
  contactIcon,
  spotifyLogo,
  facebookLogo,
  instagramLogo,
  savorlyFooter,
  contactIllustration,
} from "../utils/imageUpload.js"
import { Footer, SubscribeArea } from "../styles/FooterStyles.js"
import BodyWrapper from "../templates/BodyWrapper.js"
import { Link } from "gatsby"

import { OutboundLink } from "gatsby-plugin-google-gtag"

const FooterComp = () => {
  return (
    <Footer
      style={{ display: "flex", flexDirection: "column", margin: "0 auto" }}
    >
      <BodyWrapper always>
        <div className="phone-container">
          <div
            className="phone-img-container"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
              justifyContent: "space-between",
            }}
          >
            <img width="300px" src={savorlyFooter} alt="Savorly" />
            <a   style={{width:"40%"}}href="mailto:hello@savorly.us">
            <img
              style={{ margin: "0" }}
              className="contact"
              // width="40%"
              src={contactIllustration}
              alt="Savorly Contact"
              />
              </a>
          </div>

          <div
            className="phone"
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              height: "100%",
            }}
          >
            <div style={{}} className="col">
              <h3>Follow us!</h3>
              <div className="col-content socialMedia">
                <OutboundLink
                  className="socialMedia-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/savorly.us/"
                >
                  <img src={instagramLogo} alt="Savorly-instagram-logo" />
                </OutboundLink>
                <OutboundLink
                  className="socialMedia-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/EatSavorly/"
                >
                  <img src={facebookLogo} alt="savorly-facebook-logo" />
                </OutboundLink>
                <OutboundLink
                  className="socialMedia-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://open.spotify.com/user/d0ho7cmlhn3urfwhai6judq3t?si=8VmxtUwuTNKrLz8d37lw_w"
                >
                  <img src={spotifyLogo} alt="savorly-spotify-logo" />
                </OutboundLink>
              </div>
            </div>

            <SubscribeArea className="col">
              <h3 className="spacing ">Don't miss out!</h3>
              <div className="col-content">
                <Subscribe />
              </div>
            </SubscribeArea>
            <div className="col">
              <h3
                className="BundaySlabBold"
                style={{ color: "var(--darkBlue)" }}
              >
                #maketimetosavor
              </h3>

              <img
                className="contact-phone"
                width="30%"
                src={contactIcon}
                alt="savorly-contact"
              />
              <div className="col-content">
                <div className="grid-link">
                  {/* <Link className="faq-link" to="/shop">
                    <p>Shop</p>
                  </Link>
                  <Link className="faq-link" to="/about">
                    <p>About</p>
                  </Link> */}
                  <Link className="faq-link" to="/find-us">
                    <p>Find Us</p>
                  </Link>
                  {/*  <Link className="faq-link" to="/blog">
                    <p>Blog</p>
                  </Link> */}
                  <Link className="faq-link" to="/faq">
                    <p>FAQ</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BodyWrapper>
    </Footer>
  )
}
export default FooterComp
