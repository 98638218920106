import styled from "styled-components"

export const StyledBurger = styled.button`
  position: relative;

  // position: absolute;
  // top: 30%;
  // right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => (open ? "#0D0C1D" : "#EFFFFA")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    background-color: #003157;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

export const StyledMenu = styled.nav`
  display: flex;

  background: rgb(29, 202, 211);
  transform: ${({ open }) => (open ? "translateX(4%)" : "translateX(104%)")};
  height: 100vh;

  padding: 7rem 2rem;
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;

  width: 80%;

  div a {
    font-size: 1.5em;
    text-transform: uppercase;
    padding: 1rem 0;
    font-weight: 500;
    letter-spacing: 0.5rem;
    color: var(--darkBlue);
    text-decoration: none;
    transition: color 0.3s linear;
    padding: 1.5rem 0;
    text-align: left;
    color: rgb(0, 49, 87);
    letter-spacing: 0.01em;
    display: flex;
    justify-content: start;

    &:hover {
      color: white;
      @media (max-width: 769px) {
        color: var(--darkBlue);
      }
    }
  }

  .activeSideNav {
    color: var(--white);
    font-weight: 800;
  }

  div {
    display: flex;
    flex-direction: column;
    float: left;
  }
`
export const CartSection = styled.section`
  /* position: absolute;
  right: 0;
  top: 0; */
  /* height: 50%; */
  grid-area: cart;
  height: 30%;
  align-self: center;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    width: 10vw;
  }
`

export const BurgerWrapper = styled.div`
  /* margin: 0 20px; */
  display: flex;
  justify-content: space-between;
  /* width: 100vw; */
  align-items: center;
  @media (min-width: 769px) {
    display: none;
  }

  @media (max-width: 600px) {
    width: 50%;
  }
`

export const SubscribeMessage = styled.div`
  width: 100vw;
  background: ${props =>
    props.output === "Thank you for subscribing!"
      ? "rgb(105, 179, 73)"
      : "var(--darkBlue)"};
  color: var(--white);
  text-align: center;
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;

  animation: fadeinout 4s linear forwards;
  -webkit-animation: fadeinout 4s linear forwards;

  @-webkit-keyframes fadeinout {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
  }

  @keyframes fadeinout {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
  }
`

export const Nav = styled.nav`
  position: fixed;
  height: var(--mainMargin);
  top: 0;
  width: 100vw;
  background: var(--orange);
  z-index: 3;

  .base {
    width: 60%;
    max-width: var(--huge);
    margin: 0 auto;
    height: 100%;

    display: grid;
    grid-template-columns: 1.5fr 3fr 0.5fr;
    grid-template-areas: "logo links cart";
    grid-gap: 20px;

    @media (max-width: 1000px) /* laptopScreen */ {
      max-width: var(--laptopWidth);
      justify-content: space-between;
      width: 70%;
    }

    @media (max-width: 900px) /* smallLaptop */ {
      max-width: var(--smallLaptopWidth);
      /* background: green; */
      grid-template-columns: 1fr 2fr auto;
      grid-gap: 25px;
    }

    @media (max-width: 812px) {
      width: 70%;
    }
    @media (max-width: 769px) /* tablet */ {
      width: 90%;
      max-width: 500px;
      /* margin: 0 auto; */
      grid-template-columns: auto 1fr auto;
      grid-template-areas: "cart logo burger";
      /* background: purple; */
      grid-gap: 50px;
    }

    @media (max-width: 600px) /* phone */ {
      max-width: 90%;
      grid-gap: 20px;
      margin: 0 auto;
      /* background: lightblue; */
    }
  }
`

export const NavLink = styled.ul`
  margin: 0;
  margin: 0;
  box-sizing: border-box;
  grid-area: links;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.4rem;
  li {
    margin: 0;
    padding: 0;
    margin-right: 10px;
    text-align: center;
    :last-child {
      margin-right: 0;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05rem;

    &:hover {
      padding-bottom: 5px;
      border-bottom: 2px solid white;
      @media (max-width: 769px) {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }

  .activeNav {
    padding-bottom: 5px;
    font-weight: 800;
    border-bottom: 2px solid white;
  }

  @media (max-width: 900px) {
    font-size: 1.2rem;
    letter-spacing: 0.025rem;
  }

  @media (max-width: 769px) {
    display: none;
  }
`

export const LogoWrapper = styled.section`
  display: grid;
  grid-area: logo;
  place-items: center;
  /* margin-top: 13px; */
  height: 100%;

  @media (min-width: 1000px) {
    width: 90%;
    text-align: center;
  }
  img {
    margin-bottom: 0;
    /* width: 80%; */
    margin: 0 auto;
    display: flex;
  }

  @media (min-width: 1000px) {
    img {
      /* margin-left: 20px; */
    }
  }

  @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    img {
      margin-left: 20px;
    }
  }

  @media (max-width: 769px) {
    margin: 0 auto;
    img {
      width: 80%;
    }
  }
`

export const CartButton = styled.button`
  background: transparent;
  border: none;
  position: relative;
  align-self: center;
  cursor: pointer;

  @media (max-width: 1000px) {
    width: 50px;
  }

  .quantity {
    color: white;
    background: rgba(0, 0, 0, 0.2);
    font-weight: 600;
    border-radius: 15px;
    text-align: center;
    font-size: 1rem;
    height: 30px;
    line-height: 30px;
    width: 30px;
    position: absolute;
    // right: 60px;
    left: 15px;
    top: -10px;

    @media (max-width: 1000px) {
      // right: -5px;
      left: 20px;
    }

    @media (max-width: 767px) {
      left: 15px;
    }
  }

  img {
    height: 1.7rem;
    /* width: 100%; */
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    img {
      height: 1.5rem;
    }
  }
`
