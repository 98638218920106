import React from "react"
import { Link } from "gatsby"
import { websiteMap } from "./Navbar"
import { StyledMenu } from "../../styles/NavbarStyles"

const Menu = ({ open }) => {
  return (
    <StyledMenu open={open}>
      <div>
        {websiteMap.map(el => (
          <Link activeClassName="activeSideNav" key={el.link} to={el.link}>
            {el.where}
          </Link>
        ))}
      </div>
    </StyledMenu>
  )
}

export default Menu
