import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import ReactPixel from "react-facebook-pixel"
import { useStore } from "../../Context/StoreContext"
import {
  Container,
  ProductWrapper,
  Button,
  ShoppingHeader,
  ShoppingCartWrapper,
  Overview,
  NonVisible,
} from "../../styles/ShoppingCartStyles"
import Bundler from "./Bundler"

const ShoppingCart = (props) => {
  const { state, toggleCart } = useStore()

  // this is a test by doing the updating according to the state.cart
  const [cart, setCart] = useState(state.cart)

  useEffect(() => {
    setCart(state.cart)
  }, [state.cart])

  const [message] = useState("")
  const isCartEmpty = state.cart.length === 0

  function paymentCheckout() {
    if (isCartEmpty) {
      return
    } else {
      if (typeof window !== "undefined") {
        window.open(state.checkout.webUrl, "_self")
        const cartContents = cart.map((el) => {
          if (Array.isArray(el.products)) {
            return el.products.map((product) => {
              return {
                title: product.title,
                price: product.price,
                quantity: product.quantity,
              }
            })
          }
        })

        ReactPixel.track("InitiateCheckout", {
          contents: cartContents,
        })

        if (window.gtag) {
          window.gtag("event", "begin_checkout", {
            items: cartContents,
          })
        }
      }
    }
  }

  return (
    <>
      <ShoppingCartWrapper openCart={state.isCartOpen}>
        <Container className="flex center">
          <ShoppingHeader>
            <h3>Shopping Cart</h3>
            <Button
              exit
              color="var(--darkBlue)"
              bg="transparent"
              onClick={toggleCart}
            >
              Exit
            </Button>
          </ShoppingHeader>
        </Container>
        <Container className="flex center">
          <div className="checkout-detail-container">
            <Overview>
              <ProductWrapper className="flex flex-col">
                {cart.length === 0 ? (
                  <p>Your shopping cart is empty.</p>
                ) : (
                  cart.map((el, i) => <Bundler key={i} {...el} />)
                )}
              </ProductWrapper>

              <div className="flex total dotted-line"></div>

              <div className="flex total">
                <h3>Total</h3>
                <h4>${state.total.toString()}</h4>
              </div>
              <p onClick={toggleCart}>
                Looking for more?
                <Link to="/shop/">Continue Shopping</Link>
              </p>
            </Overview>
          </div>
        </Container>

        <Container className="flex center">
          <div className=" checkout-container flex flex-col">
            <div>
              <p>{message}</p>
            </div>
          </div>
        </Container>
        <Container className=" flex center">
          <Button
            disabled={isCartEmpty ? "disabled" : ""}
            color={"var(--white)"}
            bg={"var(--orange)"}
            onClick={paymentCheckout}
            checkout
          >
            Checkout
          </Button>
        </Container>
      </ShoppingCartWrapper>
      <NonVisible onClick={toggleCart} openCart={state.isCartOpen} />
    </>
  )
}

export default ShoppingCart
