import styled from "styled-components"

export const Product = styled.div`
  padding: 0 20px 0 0;
  .productImg {
    width: 10%;
    margin: 0;
  }

  .productName {
    padding-left: 5px;
    margin: 0;

    font-weight: 500;
  }

  align-items: end;
  width: 100%;

  align-items: center;
  margin: 10px 0;
  padding: 0 20px 0 0;

  img {
    max-width: 100%;
    max-height: 100%;
    margin-right: 20px;
    transition: transform 0.5s ease;
  }

  img:hover {
    transform: scale(1.1);
    @media (max-width: 769px) {
      transform: "";
    }
  }

  .info-div {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    margin-left: 3px;

    & div {
      justify-content: flex-start;
      align-items: center;
    }

    & p {
      margin: 0;
    }
  }

  select::-ms-expand {
    display: none;
  }
  select {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    color: var(--mainDark);
    padding-right: 25px;
    padding-left: 5px;
    position: relative;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='20' height='20' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill=' midnightblue'/></g></svg>")
      no-repeat;
    background-position: right 0 top 50%;
    outline: none;
  }

  select:focus {
    outline: none;
  }
`
export const Container = styled.div`
  height: 100%;
  color: var(--darkBlue);

  @media (max-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }

  .flex {
    display: flex;
  }

  .center {
    justify-content: center;
  }

  .flex-col {
    flex-direction: column;
  }

  .next-steps-div h3 {
    padding: 20px 0;
    border-bottom: 2px dotted var(--orange);
  }

  .remove-coupon {
    color: var(--orange);
    background: transparent;
    border: none;

    &:hover {
      cursor: pointer;
    }
  }
  .input-coupon {
    // border-radius: 8px;
    background-color: transparent;
    border: 1px solid var(--darkBlue);
    color: var(--darkBlue);
    padding: 3px 10px;
    margin-right: 5px;
    font-weight: 300;

    &:focus {
      outline: none;
      color: var(--darkBlue);
      /* @media (max-width:768px) {
      color
    } */
    }
  }
`

export const ShoppingHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0;
  }
`
export const ShoppingCartWrapper = styled.main`
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr;
  z-index: 90;
  padding: 2rem;
  background: var(--white);
  transform: ${(props) =>
    props.openCart ? "translateX(0%)" : "translateX(100%)"};
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  height: 100vh;
  width: 30vw;

  @media (max-width: 900px) {
    width: 50vw;
  }

  @media (max-width: 768px) {
      grid-template-rows: auto auto .5fr;

    width: 100vw;
    padding: 0;
    top: 0;
    left: 0;
    transform: ${(props) =>
      props.openCart ? "translateX(0)" : "translateX(-100%)"};
  }

  .checkout-detail-container {
    display: flex;
    flex-direction: column;

    margin: 0 auto;
    height: 100%;
  }

  .checkout-container {
    h3 {
      margin-bottom: 10px;
    }

    p {
      margin: 10px 0;
      word-wrap: break-word;
    }
  }

  .header {
    justify-content: flex-end;
  }

  @media (min-width: 768px) {
    grid-template-rows: auto auto 1fr;

    .checkout-detail-container {
      align-items: "";
    }

    .checkout-container {
      height: 40vh;
      height: 100%;
    }
  }

  h5 {
    margin: 0;
    margin-top: 20px;
  }
  p {
    margin: 0;
    color: var(--mainDark);
  }

  a {
    margin: 0 5px;
    color: var(--mainDark);
    text-decoration: none;

    & :hover {
      color: var(--orange);
      @media (max-width: 769px) {
        color: var(--mainDark);
      }
    }
  }

  .security {
    font-weight: 100;
    font-size: 0.9em;
    text-align: center;
    color: var(--orange);
    margin-bottom: 20px;
    // margin-top: 10vh;
  }

  .form {
    margin-bottom: 0;
  }
`
export const Wrapper = styled.div`
  /* overflow-y: scroll; */
  display: flex;
  z-index: 90;
  padding: 0 2rem;
  background: var(--white);
  transform: ${(props) =>
    props.openCart ? "translateX(0%)" : "translateX(50vw)"};
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  height: 100vh;
  /* width: 30vw; */

  @media (max-width: 900px) {
    width: 50vw;
  }

  @media (max-width: 768px) {
    width: 100vw;
    padding: 0;
    top: 0;
    left: 0;
    transform: ${(props) =>
      props.openCart ? "translateX(0)" : "translateX(-100%)"};
  }

  .checkout-detail-container {
    display: flex;
    flex-direction: column;

    margin: 0 auto;
    height: 100%;
  }

  .checkout-container {
    margin-top: 20px;
    height: 100%;

    h3 {
      margin-bottom: 10px;
    }

    p {
      margin: 5vh 10px 5vh 0;
    }
  }

  .header {
    justify-content: flex-end;
  }

  @media (min-width: 768px) {
    .checkout-detail-container {
      align-items: "";
    }

    .checkout-container {
      height: 40vh;
      height: 100%;
    }
  }

  h5 {
    margin: 0;
    margin-top: 20px;
  }
  p {
    margin: 0;
    color: var(--mainDark);
  }

  a {
    margin: 0 5px;
    color: var(--mainDark);
    text-decoration: none;

    & :hover {
      color: var(--orange);
      @media (max-width: 769px) {
        color: var(--mainDark);
      }
    }
  }

  .security {
    font-weight: 100;
    font-size: 0.9em;
    text-align: center;
    color: var(--orange);
    margin-bottom: 20px;
    margin-top: 10vh;
  }

  .form {
    margin-bottom: 5vh;
  }
`
export const Overview = styled.div`
  width: 100%;

  .dotted-line {
    border-top: 2px dotted var(--orange);
  }

  .total {
    padding: 0 20px 0 0;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    & h3 {
      margin-bottom: 0 !important;
    }
    & h4 {
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    .total {
      padding: 0;
    }
  }
`

export const ProductWrapper = styled.div`
  height: 40vh;
  overflow-y: scroll;
`

export const Button = styled.button`
  background-color: ${(props) => props.bg};
  height: ${(props) => (props.remove ? "" : "6vh")};
  color: ${(props) => props.color};
  border: none;
  /* border-radius: ${(props) => (props.checkout ? "0" : "8px")}; */

  width: ${(props) => (props.checkout ? "100%" : "")};

  &:hover {
    cursor: pointer;

    /* background-color: ${(props) => (props.exit ? "" : "rgb(6, 66, 116)")}; */
    @media (max-width: 769px) {
      background-color: ${(props) => props.bg};
    }
  }

  &:disabled {
    background-color: none;
    opacity: 0.5;
  }

  a {
    color: white;
    text-decoration: none;

    :hover {
      color: white;
    }
  }
`

export const ButtonCoupon = styled(Button)`
  border-radius: 0px;
  height: fit-content;
  padding: 3px 10px;
  &:hover {
    background-color: rgb(6, 66, 116);
    color: var(--white);
    @media (max-width: 769px) {
      background-color: ${(props) => props.bg};
      color: ${(props) => props.color};
    }
  }
  &:disabled {
    opacity: 0.3;
  }
`

export const NonVisible = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  transform: ${(props) =>
    props.openCart ? "translateX(0%)" : "translateX(100%)"};
  // transition: transform 0.1s ease-in-out;
  z-index: 4;
  top: 0;
  background-color: rgba(1, 1, 1, 0.1);
`
