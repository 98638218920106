import React from "react"
import ProductCart from "./ProductCart"
import { Button } from "../../styles/ShoppingCartStyles"
import { useStore } from "../../Context/StoreContext"

const Bundler = ({ name, products }) => {
  const {
    cleanerRemoveFromCart,
    state: { checkout, isLoading },
  } = useStore()

  function remove() {
    if (isLoading) return
    cleanerRemoveFromCart(name)
  }
  // console.log(name)
  return (
    <div style={{ width: "93%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        // onClick={() => cleanerRemoveFromCart(name)}
      >
        <p style={{ marginTop: 0 }}>
          {/* {name} */}
          {name.split("-")[0]}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <Button
            remove
            color="var(--orangeText)"
            bg="transparent"
            onClick={remove}
          >
            X
          </Button>
          <p>
            {name.includes("Welcome")
              ? "$79.99"
              : name.includes("Bundle Pick 5")
              ? "$69.99"
              : "$99.92"}
          </p>
        </div>
      </div>
      <div style={{ paddingLeft: "1rem" }}>
        {products.map((product, i) => (
          <ProductCart
            key={i}
            {...product}
            welcomeB={name.includes("Welcome")}
          />
        ))}
      </div>
    </div>
  )
}

export default Bundler
