import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle` 
  :root {
  --pink: rgb(244, 67, 108);
  --blue: rgb(29, 202, 211);
  --darkBlue: rgb(0, 48, 87);
  --darkBlueTransparency: rgba(0,48,87,0.5);
  --beige: rgb(249, 237, 216);
  --orange: rgb(255, 141, 107);
  --orangeText: #de9b27;
  --white: #ffffff;
  --black: rgb(1, 1, 1);
  --mainMargin: 6rem;
  --huge: 1150px;

  --bigScreen: 1200px;
  --bigWidth: 936px;
  --laptopScreen: 1000px;
  --laptopWidth: 800px;
  --smallLaptopScreen: 900px;
  --smallLaptopWidth: 650px;
  --tabletScreen: 700px;
  --tabletWidth:580px;
  --phoneScreen:600px;
  --phoneWidth: 90vw;
}
* {
  margin: 0;
  padding: 0;
  // font-family: sans-serif;
}
html {
  // font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`
