import savorlyLogo from "../dev_assets/SavorlyLogoNav.png"
import shoppingCart from "../dev_assets/cart_icon.svg"
// import aboutLine from "../dev_assets/AboutPage/Line.png"
// import MiniPiesSideIllustration from "../dev_assets/HomePage/MiniPies_Side_Illustrations.png"
import MiniPiesIllustration from "../dev_assets/HomePage/MiniPies_Illustration.png"
import MiniPies from "../dev_assets/HomePage/Mini_Pies.gif"
// import MezzeBitesSideIllustration from "../dev_assets/HomePage/MezzeBites_Side_Illustrations.png"
import MezzeBitesIllustration from "../dev_assets/HomePage/MezzeBites_Illustration.png"
import MezzeBites from "../dev_assets/HomePage/Mezze_Bites.png"
import instagramLogo from "../dev_assets/Footer/Instagram_Logo.svg"
import facebookLogo from "../dev_assets/Footer/Facebook_Logo.svg"
import spotifyLogo from "../dev_assets/Footer/Spotify_Logo.svg"
import contactIcon from "../dev_assets/Footer/Contact_Illustration.png"
import contactIllustration from "../dev_assets/Footer/Contact_Illustration.png"
import savorlyFooter from "../dev_assets/Savorly_Footer.png"

//About Page

import Image_Asset_1 from "../dev_assets/AboutPage/Image_Asset_1.png"
import Image_Asset_2 from "../dev_assets/AboutPage/Image_Asset_2.gif"

import Image_Asset_3 from "../dev_assets/AboutPage/Image_Asset_3.png"
import Image_Asset_4 from "../dev_assets/AboutPage/Image_Asset_4.png"
import Image_Asset_5 from "../dev_assets/AboutPage/Image_Asset_5.png"
import Image_Asset_6 from "../dev_assets/AboutPage/Image_Asset_6.png"

//Shop Baking Banner
import BakingBanner01 from "../images/Shop/BakingBanner-01.png"
import BakingBanner02 from "../images/Shop/BakingBanner-02.png"
import BakingBanner03 from "../images/Shop/BakingBanner-03.png"
import BakingBanner04 from "../images/Shop/BakingBanner-04.png"
import BakingBanner05 from "../images/Shop/BakingBanner-05.png"

import Fire04 from "../images/Shop/Fire04.png"
import OpenOven03 from "../images/Shop/OpenOven03.png"
import Oven01 from "../images/Shop/Oven01.png"
import Pastry05 from "../images/Shop/Pastry05.png"
import Tray02 from "../images/Shop/Tray02.png"

import progressBarItem from "../images/Shop/progressBar.png"
import ship from "../images/Shop/ship.png"
import pack from "../images/Shop/pack.png"
import box from "../images/Shop/box.png"
//Footer

import Logo1 from "../dev_assets/Find-us/Find Us Store 1-01.png"
import Logo2 from "../dev_assets/Find-us/Find Us Store 2-01.png"
import Logo3 from "../dev_assets/Find-us/Find Us Store 3-01.png"
import Logo4 from "../dev_assets/Find-us/Find Us Store 4-01.png"
import Logo5 from "../dev_assets/Find-us/Find Us Store 5-01.png"
import Logo6 from "../dev_assets/Find-us/Find Us Store 6-01.png"
import Logo7 from "../dev_assets/Find-us/Find Us Store 7-01.png"
import Logo8 from "../dev_assets/Find-us/Costco_Logo.png"
// import Logo9 from "../dev_assets/Find-us/New_Seasons_logo.png"
import Logo10 from "../dev_assets/Find-us/Wegmans_Logo.min.svg"
import Logo11 from "../dev_assets/Find-us/Dierbergs.png"
import Logo12 from "../dev_assets/Find-us/Harmons.png"
import instaIcon from "../images/InstaIcon.png"

//Home page
import sellingTitle from "../dev_assets/HomePage/HomePage-Best-Selling-GIF.gif"
import GratinBlue from "../dev_assets/HomePage/GratinBlue.png"
// import Badge from "../dev_assets/HomePage/Badge.png"
import GratinGreen from "../dev_assets/HomePage/GratinGreen.png"

import CocktailBites from "../dev_assets/HomePage/Cocktail_Bites.png"
import FrozenVegi from "../dev_assets/ProductPage/FrozenVegi.png"
import Fridge from "../dev_assets/ProductPage/Fridge.png"


//ibotta
import ibotta1 from "../dev_assets/ibotta1.png"
import ibotta2 from "../dev_assets/ibotta2.png"
import ibotta3 from "../dev_assets/ibotta3.png"
import ibotta_logo1 from "../dev_assets/ibotta_logo1.png"
import ibotta_logo2 from "../dev_assets/ibotta_logo2.png"
import ibotta_logo3 from "../dev_assets/ibotta_logo3.png"
import ibotta_logo4 from "../dev_assets/ibotta_logo4.png"
import ibotta_logo5 from "../dev_assets/ibotta_logo5.png"
import ibotta_logo6 from "../dev_assets/ibotta_logo6.png"
import ibotta_logo7 from "../dev_assets/ibotta_logo7.png"
import ibotta_gif from "../dev_assets/ibotta_gif.gif"
import ibotta_landing from "../dev_assets/ibotta_landing.png"


export {
  // Badge,
  ibotta_landing,
  ibotta_gif,
  ibotta_logo1,
  ibotta_logo2,
  ibotta_logo3,
  ibotta_logo4,
  ibotta_logo5,
  ibotta_logo6,
  ibotta_logo7,
  ibotta1,
  ibotta2,
  ibotta3,
  instaIcon,
  FrozenVegi,
  Fridge,
  GratinBlue,
  GratinGreen,
  CocktailBites,
  sellingTitle,
  Fire04,
  OpenOven03,
  Oven01,
  Pastry05,
  Tray02,
  BakingBanner01,
  BakingBanner02,
  BakingBanner03,
  BakingBanner04,
  BakingBanner05,
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
  Logo6,
  Logo7,
  Logo8,
  // Logo9,
  Logo10,
  Logo11,
  Logo12,
  Image_Asset_1,
  Image_Asset_2,
  Image_Asset_3,
  Image_Asset_4,
  Image_Asset_5,
  Image_Asset_6,
  savorlyLogo,
  shoppingCart,
  progressBarItem,
  ship,
  pack,
  box,
  // aboutLine,
  // MiniPiesSideIllustration,
  MiniPiesIllustration,
  MiniPies,
  // MezzeBitesSideIllustration,
  MezzeBitesIllustration,
  MezzeBites,
  instagramLogo,
  facebookLogo,
  spotifyLogo,
  contactIcon,
  contactIllustration,
  savorlyFooter,
}
