import React from "react"
import styled from "styled-components"

const BodyWrapper = ({ marginNon, children, bg, always, nav }) => {
  return (
    <BodyWrapperStyles always={always} bg={bg} nav={nav}>
      <div className="sonOfWrapper">{children}</div>
    </BodyWrapperStyles>
  )
}

const BodyWrapperStyles = styled.div`

background: ${(props) => props.bg || "none"};
background-color: ${(props) => props.bg || "none"};
height: ${(props) => (props.nav ? "100%" : "")};
display: ${(props) => (props.nav ? "flex" : "")};


/*   .sonOfWrapper {
  margin: ${(props) => (props.always ? "0 auto" : "")};
  max-width: ${(props) => (props.always ? "var(--huge)" : "")};
} */

@media (min-width: 601px) and (max-width: 767px) {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
  }

@media (min-width: 768px)  /* laptopScreen */ {

  width: 60%;
  
}
margin: 0 auto;


@media (max-width: 1000px) and (min-width: 769px) /* laptopScreen */ {
  width: 70%;
  
}

  @media (max-width: 600px) /* phone */ {
    max-width: 90%;

  }

`

export default BodyWrapper
