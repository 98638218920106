import React, { useState } from "react"
import styled from "styled-components"
import { useStore } from "../Context/StoreContext"

const Subscribe = (props) => {
  const [email, setEmail] = useState("")
  const { message } = props
  const { subscribe } = useStore()

  async function handleSubmit(e) {
    e.preventDefault()
    const res = await subscribe(email)
    if (res) {
      setEmail("")
    }
  }

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  return (
    <div>
      <SubscribeForm
        message={message}
        about={props.about}
        style={{ margin: "0" }}
        className="emailQuery"
        onSubmit={handleSubmit}
      >
        <div className="email-input">
          <input
            placeholder="Enter your email here*"
            type="email"
            value={email}
            onChange={handleChange}
          />
        </div>
        <input type="submit" value="Subscribe" />
      </SubscribeForm>
    </div>
  )
}

const SubscribeForm = styled.form`
  display: flex !important;
  justify-content: center;

  input {
    background-color: transparent;
    border: 1px solid var(--white);
    color: var(--darkBlue);
    padding: 5px 10px;
    font-weight: 600;
  }

  input:focus {
    outline: none;
    color: var(--darkBlue);
  }

  input[type="email"] {
    border-radius: none;
  }

  input[type="submit"] {
    background-color: ${(props) =>
      props.about ? "var(--darkBlue)" : "var(--white)"};
    color: ${(props) => (props.about ? "var(--white)" : "")};
  }
  input[type="submit"]:hover {
    background-color: var(--white);
    color: var(--darkBlue);
    cursor: pointer;
  }

  .emailQuery {
    display: flex;
  }
`

export default Subscribe
