import React from "react"
import {Product } from "../../styles/ShoppingCartStyles"

const ProductCart = (props) => {
  const { quantity, title } = props
  return (
    <Product className="flex">
      <img
        fadeIn={true}
        src={props.images[0].localFile.childImageSharp.fluid.src}
        className="productImg"
      />
      <div className="info-div flex ">
        <div className="flex">
          <p>{quantity}x </p>
          <p className="productName"> {title}</p>
        </div>
      </div>
    </Product>
  )
}

export default ProductCart
