import React from "react"
import { shoppingCart } from "../../utils/imageUpload"
import { CartButton } from "../../styles/NavbarStyles"

const OpenCart = ({ totalItems, onClick }) => {
  return (
    <CartButton onClick={onClick}>
      {totalItems > 0 && <div className="quantity">{totalItems}</div>}
      <img src={shoppingCart} alt="Savorly" />
    </CartButton>
  )
}

export default OpenCart
