import styled from "styled-components"

export const SubscribeArea = styled.div`
  padding: 1rem;
  // width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  align-items: end !important;
  color: var(--white);

  .message {
    font-size: 1rem;
    text-align: center;
    margin-top: 1rem;
  }
  & img {
    margin: 0;
  }
  @media (max-width: 769px) {
    .col-content {
      flex-direction: column;
    }
  }

  .col {
    // padding: 0.5rem;
    // width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      margin-bottom: 0;
      padding-bottom: 20px;
    }
    & img {
      margin: 0;
    }

    .socialMedia-link {
      // height: 50px;
      width: 40px;

      img {
        width: 100%;
      }
    }
  }
`

export const Footer = styled.footer`
  * {
    font-family: "FuturaPTWebMedium", "FuturaPTCond-Medium", sans-serif;
  }
  display: flex;
  background-color: var(--orange);

  display: flex;
  justify-content: space-evenly;

  .grid-link {
    margin: 0 auto;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(1, 1fr);
    /* grid-gap: 5px; */

    .faq-link {
      text-decoration: none;
      color: white;

      p {
        font-weight: 100;
        margin: 0;
      }
    }

    @media (max-width: 769px) {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
    }
  }
  .phone-container {
    height: 100%;
    // width: 80vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 1500px) {
      width: 100%;
    }
  }

  .BundaySlabBold {
    font-family: "BundaySlab-SemiBoldUp", sans-serif;
  }

  h2 {
    margin: 0;
    font-family: FuturaPTWebMedium, FuturaPTCond-Medium;
  }

  .col {
    // padding: 0.5rem;
    // width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--white);

    h3 {
      margin-bottom: 0;
      padding-bottom: 20px;
    }
    & img {
      margin: 0;
    }

    .socialMedia-link {
      // height: 50px;
      width: 40px;

      img {
        width: 100%;
      }
    }
  }

  .col-content {
    height: 6vh;

    align-items: center;
    display: flex;
  }

  .contact-phone {
    display: none;
  }

  @media (max-width: 769px) {
    height: fit-content;

    .contact {
      display: none;
    }

    .contact-phone {
      display: block !important;
      width: 80%;
      margin-top: 30px !important;
    }

    .phone-container {
      width: 100%;
      margin: 0;
      align-items: center;
      padding: 20px 0;
    }
    .phone {
      display: flex;
      flex-direction: column;
      width: 100vw;
      justify-content: center;
      align-items: center;
    }

    .phone-img-container {
      justify-content: center !important;
      text-align: center;
    }

    .col {
      width: 100vw;
      align-items: center !important;
      margin-bottom: 10px;
    }
    .col-content {
      margin-top: 20px;
    }
  }
`
